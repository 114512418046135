import {
	BOOKING_ADD_TO_CART,
	BOOKING_REMOVE_FROM_CART,
	BOOKING_SHOW_CART,
	BOOKING_HIDE_CART,
	BOOKING_TOGGLE_CART,
	BOOKING_SEARCH_LOAD,
	BOOKING_SEARCH_LOADED,
	PUNTO_CARTA_LOAD,
	LOAD_SERVICIO_DETAIL,
	SERVICIO_DETAIL_LOADED,
	UPDATE_SEARCH_PARAMS,
	UPDATE_SEARCH_PARAMS_HOTEL,
	CLEAR_SEARCH,
	RESERV_CONF_OF_SERVICE_QUERY,
	RESERV_CONF_OF_SERVICE_DATA,
	RESERV_CONF_OF_SERVICE_CLEAR,
	RESERV_PRICE_QUERY,
	RESERV_PRICE_DATA,
	RESERV_PRICE_CLEAR,
	BOOKING_CLEAR_CART,
	BOOKING_LOCK_DROPDOWN_CART,
	QUOTE_CHECKOUT_SAVE,
	QUOTE_CHECKOUT_RESPONSE,
	RESERV_QUOTE_SAVE,
	RESERV_QUOTE_RESPONSE,
	RESERV_QUOTE_ERROR,
	RESERV_CHECKOUT_SAVE,
	RESERV_CHECKOUT_RESPONSE,
	RESERV_CHECKOUT_CLEAR,
	RESERV_EDIT_MODE_ON,
	RESERV_EDIT_MODE_OFF,
	BOOKING_SEARCH_HOTEL_LOAD,
	BOOKING_SEARCH_HOTEL_LOADED,
	CLEAR_HOTEL_SEARCH,
	HOTEL_ACOMODACION_REQUEST,
	HOTEL_ACOMODACION_RESPONSE,
	HOTEL_ZONAS_REQUEST,
	HOTEL_ZONAS_RESPONSE,
	HOTEL_PRICE_ACOMODACION_REQUEST,
	HOTEL_PRICE_ACOMODACION_RESPONSE,
	ERASE_HOTELES_CART,
	CHANGE_ORIGEN,
	HOTEL_DISPONIBILIDAD_REQUEST,
	HOTEL_DISPONIBILIDAD_RESPONSE,
	HOTEL_DISPONIBILIDADES_REQUEST,
	HOTEL_DISPONIBILIDADES_RESPONSE,
} from 'constants/ActionTypes';


const initSearch = {
	fechaFin: undefined,
	fechaIni: undefined,
	idDestino: undefined,
	nombreDestino: undefined,
	clear: false,
	tipo: undefined,
	page:1,
	pax:1,
	orderPrice: 0,
};
const initHotelSearch = {
	fecha_ini: undefined,
	fecha_fin: undefined,
	destino: undefined,
	clear: false,
	adultos: 1,
	menores: 0,
	habitaciones: 1,
	page:1,
	orderPrice: 0,
	orderStar: undefined,
	categoria: "",
	nombre_categoria: ""
};
const initSearchResults = {
	data:null,
	last_page:1
};
const initSearchHotelResults = {
	data:null,
	last_page:1
};
const INIT_STATE = {
	savingReservation:false,
	editingReservation:false,
	cart:JSON.parse(localStorage.getItem("cart")) || [],
	passengers:[],
	lastActivity:null,
	expires:null,
	loading:false,
	loadingCotizacion:false,
	loadingPrice:false,
	showCart:false,
	searchResults:initSearchResults,
	puntoResults:[],
	searchHotelResults:initSearchHotelResults,
	servicioDetail:null,
	searchQuery:initSearch,
	searchQueryHotel:initHotelSearch,
	bookFrom:null,
	confService: null,
	loadingConfig:false,
	price: null,
	lockDropdown: false,
	responseSavedCheckout: null,
	responseSavedCotizacion: null,
	showErrorModal:false,
	editValue:JSON.parse(localStorage.getItem("edit_extra")) || null,
	acomodaciones: [],
	loadingAcomodaciones: false,
	zonas: [],
	loadingZonas: false,
	priceAcomodacion: 0,
	tipoViaje: JSON.parse(localStorage.getItem("tipo_viaje")) || 0, // Reside en colombia? default: no!
	loadingDisponibilidad: false,
	disponibilidad: null,
	disponibilidades: [],
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case BOOKING_ADD_TO_CART: {
			const {cart} = state;
			cart.push(action.payload);
			localStorage.setItem("cart",JSON.stringify(cart));
			return {
				...state,
				cart:cart,
				showCart:true
			}
		}
		case BOOKING_REMOVE_FROM_CART: {
			let cart = JSON.parse(localStorage.cart);
			let edit_extra = localStorage.edit_extra && JSON.parse(localStorage.edit_extra);

			cart.forEach((v,i)=>{
				if(v.uid === action.payload){
					// mode edit: on
					if(edit_extra){
						if(v.tipo === 'acomodacion'){
							if(!edit_extra.id_acomodaciones_eliminados){
								edit_extra.id_acomodaciones_eliminados = [v.id];
							}else{
								edit_extra.id_acomodaciones_eliminados.push(v.id);
							}
						} else {
							if(!edit_extra.id_servicios_eliminados){
								edit_extra.id_servicios_eliminados = [v.id];
							}else{
								edit_extra.id_servicios_eliminados.push(v.id);
							}
						}
					}
					// end
					cart.splice(i, 1);
				}
			});
			localStorage.setItem("cart",JSON.stringify(cart));

			// mode edit: on
			if(edit_extra){
				localStorage.setItem("edit_extra",JSON.stringify(edit_extra));
			}
			// end

			return {
				...state,
				cart: cart,
				lockDropdown:false,
				editValue: edit_extra?edit_extra:null,
			}
		}
		case BOOKING_CLEAR_CART: {
			localStorage.setItem("cart",JSON.stringify([]));
			localStorage.removeItem("tempCurrency");

			// mode edit: on
			let edit_extra = localStorage.edit_extra && JSON.parse(localStorage.edit_extra);
			if(edit_extra){
				let cart = JSON.parse(localStorage.cart);
				cart.forEach((v,i)=>{
					if(v.tipo === 'acomodacion'){
						if(!edit_extra.id_acomodaciones_eliminados){
							edit_extra.id_acomodaciones_eliminados = [v.id];
						}else{
							edit_extra.id_acomodaciones_eliminados.push(v.id);
						}
					} else {
						if(!edit_extra.id_servicios_eliminados){
							edit_extra.id_servicios_eliminados = [v.id];
						}else{
							edit_extra.id_servicios_eliminados.push(v.id);
						}
					}
				});
				localStorage.setItem("edit_extra",JSON.stringify(edit_extra));
			}
			// end

			return {
				...state,
				cart: [],
				showCart:false,
				lockDropdown:false,
				editValue: edit_extra?edit_extra:null,
			}
		}
		case ERASE_HOTELES_CART: {
			if(!localStorage.cart) return {...state};
			let cart = JSON.parse(localStorage.cart);
			let edit_extra = localStorage.edit_extra && JSON.parse(localStorage.edit_extra);

			cart.forEach((v,i)=>{
				if(v.tipo === 'acomodacion'){
					// mode edit: on
					if(edit_extra){
						if(!edit_extra.id_acomodaciones_eliminados){
							edit_extra.id_acomodaciones_eliminados = [v.id];
						}else{
							edit_extra.id_acomodaciones_eliminados.push(v.id);
						}
					}
					// end
					cart.splice(i, 1);
				}
			});
			localStorage.setItem("cart",JSON.stringify(cart));

			// mode edit: on
			if(edit_extra){
				localStorage.setItem("edit_extra",JSON.stringify(edit_extra));
			}
			// end

			return {
				...state,
				cart: cart,
				lockDropdown:false,
				editValue: edit_extra?edit_extra:null,
			}
		}
		case CHANGE_ORIGEN: {
			localStorage.setItem("tipo_viaje",action.payload);
			return {
				...state,
				tipoViaje: action.payload
			}
		}
		case BOOKING_SHOW_CART: {
			return {
				...state,
				showCart:true
			}
		}
		case BOOKING_HIDE_CART: {
			return {
				...state,
				showCart:false
			}
		}
		case BOOKING_LOCK_DROPDOWN_CART: {
			return {
				...state,
				lockDropdown: action.value,
			}
		}
		case BOOKING_TOGGLE_CART: {
			const {showCart, lockDropdown} = state;

			// Prevent close dropdown, ex. when modals are used
			if(lockDropdown) return {...state};

			return {
				...state,
				showCart:!showCart
			}
		}
		case BOOKING_SEARCH_LOAD: {
			return {
				...state,
				loading:true,
				searchQuery:action.payload
			};
		}
		case BOOKING_SEARCH_LOADED: {
			return {
				...state,
				loading:false,
				searchResults:action.payload
			};
		}
		case PUNTO_CARTA_LOAD:{
			return {
				...state,
				puntoResults:action.payload
			}
		}
		case BOOKING_SEARCH_HOTEL_LOAD: {
			return {
				...state,
				loading:true,
				searchQueryHotel:action.payload
			};
		}
		case BOOKING_SEARCH_HOTEL_LOADED: {
			return {
				...state,
				loading:false,
				searchHotelResults:action.payload
			};
		}
		case LOAD_SERVICIO_DETAIL: {
			return {
				...state,
				servicioDetail:null,
			};
		}
		case SERVICIO_DETAIL_LOADED: {
			return {
				...state,
				servicioDetail:action.payload,
			};
		}
		case UPDATE_SEARCH_PARAMS: {
			return {
				...state,
				searchQuery:{
					...state.searchQuery,
					...action.payload
				}
			};
		}
		case UPDATE_SEARCH_PARAMS_HOTEL: {
			return {
				...state,
				searchQueryHotel:{
					...state.searchQueryHotel,
					...action.payload
				}
			};
		}
		case CLEAR_SEARCH: {
			return {
				...state,
				searchQuery:initSearch,
				searchResults:initSearchResults
			};
		}
		case CLEAR_HOTEL_SEARCH: {
			return {
				...state,
				searchQueryHotel:initHotelSearch,
				searchHotelResults:initSearchHotelResults
			};
		}
		case RESERV_CONF_OF_SERVICE_QUERY: {
			return {
				...state,
				loadingConfig:true,
			};
		}
		case RESERV_CONF_OF_SERVICE_DATA: {
			return {
				...state,
				loadingConfig:false,
				confService: action.payload
			};
		}
		case RESERV_CONF_OF_SERVICE_CLEAR: {
			return {
				...state,
				confService: null
			};
		}
		case RESERV_PRICE_QUERY: {
			return {
				...state,
				loadingPrice:true,
			};
		}
		case RESERV_PRICE_DATA: {
			return {
				...state,
				loadingPrice:false,
				price: action.payload
			};
		}
		case RESERV_PRICE_CLEAR: {
			return {
				...state,
				price: null
			};
		}
		case QUOTE_CHECKOUT_SAVE: {
			return {
				...state,
				loadingCotizacion: true,
			};
		}
		case QUOTE_CHECKOUT_RESPONSE: {
			return {
				...state,
				loadingCotizacion: false,
				responseSavedCotizacion: action.payload
			};
		}
		case RESERV_QUOTE_SAVE: {
			return {
				...state,
				loading: true,
			};
		}
		case RESERV_QUOTE_RESPONSE: {
			return {
				...state,
				loading: false,
				responseSavedReservaCotizacion: action.payload
			};
		}
		case RESERV_QUOTE_ERROR: {
			return {
				...state,
				loading: false,
				responseSavedReservaCotizacion: null,
				errorCotizacion: action.payload,
				showErrorModal: true
			};
		}
		case RESERV_CHECKOUT_SAVE: {
			return {
				...state,
				loading: true,
			};
		}
		case RESERV_CHECKOUT_RESPONSE: {
			return {
				...state,
				loading: false,
				responseSavedCheckout: action.payload,
			};
		}
		case RESERV_CHECKOUT_CLEAR: {
			return {
				...state,
				responseSavedCheckout: null,
				loading: false,
				showErrorModal: !!action.payload,
			};
		}

		case RESERV_EDIT_MODE_ON:{
			localStorage.setItem("edit_extra",JSON.stringify(action.payload));

			return {
				...state,
				editValue:action.payload,
			};
		}
		case RESERV_EDIT_MODE_OFF:{
			localStorage.setItem("edit_extra",null);
			localStorage.setItem("cart",JSON.stringify([]));

			return {
				...state,
				cart: [],
				showCart:false,
				lockDropdown:false,
				editValue:null,
			}
		}
		case HOTEL_ACOMODACION_REQUEST: {
			return {
				...state,
				loadingAcomodaciones:true,
			}
		}
		case HOTEL_ACOMODACION_RESPONSE: {
			return {
				...state,
				loadingAcomodaciones:false,
				acomodaciones:action.payload,
			}
		}
		case HOTEL_ZONAS_REQUEST: {
			return {
				...state,
				loadingZonas:true,
				zonas:[],
			}
		}
		case HOTEL_ZONAS_RESPONSE: {
			return {
				...state,
				loadingZonas:false,
				zonas:action.payload,
			}
		}
		case HOTEL_PRICE_ACOMODACION_REQUEST: {
			return {
				...state,
				loadingPrice:true,
			}
		}
		case HOTEL_PRICE_ACOMODACION_RESPONSE: {
			return {
				...state,
				loadingPrice:false,
				priceAcomodacion:action.payload,
			}
		}
		// una sola
		case HOTEL_DISPONIBILIDAD_REQUEST: {
			return {
				...state,
				loadingDisponibilidad:true,
			}
		}
		case HOTEL_DISPONIBILIDAD_RESPONSE: {
			return {
				...state,
				loadingDisponibilidad:false,
				disponibilidad:action.payload,
			}
		}
		// multiple
		case HOTEL_DISPONIBILIDADES_REQUEST: {
			return {
				...state,
				loadingDisponibilidad:true,
			}
		}
		case HOTEL_DISPONIBILIDADES_RESPONSE: {
			return {
				...state,
				loadingDisponibilidad:false,
				disponibilidades:action.payload,
			}
		}
		default:
			return state;
	}
}
